import React, { useEffect, useState, useContext } from "react";
import { Input, Select } from "./../../Core";
import Autocomplete from 'react-autocomplete';
import GlobalContext from '../../../context/GlobalContext';
import { navigate } from 'gatsby';

const axios = require('axios');

const SearchKnowledgeBase = (props) => {

  const gContext = useContext(GlobalContext);

  return (
    <Select
      defaultValue=""
      getOptionLabel={(option) => option.title}
      isClearable
      name="faqArticles"
      options={gContext.faqArticles}
      isSearchable={true}
      placeholder="Ask us a question"
      className="reactSelect selectForm "
      onChange={option => {
        if (option) {
          navigate('/knowledge-base/article/' + option.article_slug);
        }
      }}
    />
  )
}

export default SearchKnowledgeBase;
